import icon1 from '../assets/Icon-1 (1).svg'
import icon2 from '../assets/Icon-2 (1).svg'
import icon3 from '../assets/Icon (2).svg'
import connect1 from '../assets/Group 19 (2).svg'
import connect2 from '../assets/Group 20.svg'
import {useState } from 'react'
import { motion } from "framer-motion"
import 'aos/dist/aos.css';
import Aos from 'aos'
import {useEffect} from 'react'

const HowItWorks = () => {
    const [toggelState, setToggleState] = useState(1);
    const toggleTab = (index) =>{
        setToggleState(index);
    }
    useEffect(() =>{
        Aos.init();
    }, [])
    return ( 
        <>
        <section id="about"
         className="bg-[#FBFBFB] py-[3rem] mt-[3rem] md:p-[5rem]">
      <div className="text-center">
          <h3 className="text-dark text-[20px] font-[600]  md:text-[35px]">How we work</h3>
          <p className="text-dark px-[1rem] opacity-75 font-[500]  ">You can get our services with just 3-steps</p>
      </div>
      <div className="container">
      <nav>
          {/* flex flex-col md:flex mb-[3rem] md:flex-row  md:justify-between */}
          <ul className='grid justify-evenly text-justify grid-cols-3 mt-[2rem] px-[0.5rem]'>
              <div className="md:hidden">
              <li onClick={() =>toggleTab(1)} className={toggelState === 1 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Real Estate</li>

              </div>
              <div className="md:hidden">
              <li onClick={() =>toggleTab(2)} className={toggelState === 2 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}>Logistics</li>

              </div>
              <div className="md:hidden">
              <li onClick={() =>toggleTab(3)} className={toggelState === 3 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}>Laundromat</li>

              </div>
              {/*           
                   <li onClick={(e) => {HandleClick(e, index);}} className={`${
                      active === index ? 'active text-[#0095B4] text-[23px] border-solid border-b-[1.5px] border-[#0095B4]' : 'text-[23px] text-[#8D8D8D]'
                    } cursor-pointer  capitalize`} ></li> */}
             
        </ul>
    </nav>
   {/* flex  flex-col md:flex-row align-items justify-center text-center */}
 <div className=" mt-5 gap-y-[3rem] ">
 {/* {services.map((item)=>{
               return <Services item={item} key={item.id} />
 
 })} */}
 <div className={toggelState === 1 ?"flex flex-col  gap-y-[2rem]  md:flex-row":"hidden"}>

        <div  className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#e5e5e525] rounded-md md:rounded-none xl:px-[3rem]">
            <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(1)} className={toggelState === 1 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Real Estate</p>

            </div>

            <div className="">
                <div className="grid justify-items-center  bg-[#9672FF] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon2} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#9672FF] md:text-dark font-[600] md:text-[20px]'>Contact Us</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Click on any of our contact us, you’ll instantly be with any of our representatives.</p> 

            </div>
        
        </div>

        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#4de0fd11] rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(2)} className={toggelState === 2 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Logistics</p>

            </div>
            <div className="">
                <div className="grid justify-items-center bg-[#4DDFFD] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon1} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#00829C] md:text-dark font-[600] md:text-[20px]'>Survery Properties</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Come and check your desired location or show us where your property is.</p> 

            </div>
        
        </div>
        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#f2b8ec34]  rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(3)} className={toggelState === 3 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Laundromat</p>

            </div>
            <div className="">
                <div className="grid justify-items-center bg-[#F2B8EC] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon3} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#FF46EC] md:text-dark font-[600] md:text-[20px]'>Payments</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Once verification is done by both parties, we proceed to paying or being paid</p> 

            </div>
        
        </div>

     

    
 </div>
 <div className={toggelState === 2 ?"flex flex-col  gap-y-[2rem] md:flex-row":"hidden"}>

        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#e5e5e525] rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
        <p onClick={() =>toggleTab(1)} className={toggelState === 1 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}>Real Estate</p>
           

            </div>
            <div className="">
                <div className="grid justify-items-center  bg-[#9672FF] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon2} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#9672FF] md:text-dark font-[600] md:text-[20px]'>Contact Us</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Click on any of our contact us, you’ll instantly be with any of our representatives.</p> 

            </div>
        
        </div>
        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#4de0fd11] rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(2)} className={toggelState === 2 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Logistics</p>

            </div>
            <div className="">
                <div className="grid justify-items-center bg-[#4DDFFD] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon1} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#00829C] md:text-dark font-[600] md:text-[20px]'>Survery Properties</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Come and check your desired location or show us where your property is.</p> 

            </div>
        
        </div>
        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#f2b8ec34]  rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(3)} className={toggelState === 3 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Laundromat</p>

            </div>
            <div className="">
                <div className="grid justify-items-center bg-[#F2B8EC] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon3} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#FF46EC] md:text-dark font-[600] md:text-[20px]'>Payments</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Once verification is done by both parties, we proceed to paying or being paid</p> 

            </div>
        
        </div>

     

    
 </div>

 <div className={toggelState === 3 ?"flex flex-col  gap-y-[2rem] md:flex-row":"hidden"}>

        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#e5e5e525] rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(1)} className={toggelState === 1 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Real Estate</p>

            </div>
            <div className="">
            
                <div className="grid justify-items-center  bg-[#9672FF] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon2} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#9672FF] md:text-dark font-[600] md:text-[20px]'>Contact Us</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Click on any of our contact us, you’ll instantly be with any of our representatives.</p> 

            </div>
        
        </div>
        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#4de0fd11] rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(2)} className={toggelState === 2 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Logistics</p>

            </div>
            <div className="">
                <div className="grid justify-items-center bg-[#4DDFFD] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon1} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#00829C] md:text-dark font-[600] md:text-[20px]'>Survery Properties</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Come and check your desired location or show us where your property is.</p> 

            </div>
        
        </div>
        <div className="flex md:text-center justify-around gap-x-[1.4rem]  md:grid md:justify-items-center px-[1rem] py-[0.5rem] md:bg-transparent bg-[#f2b8ec34]  rounded-md md:rounded-none xl:px-[3rem]">
        <div className='hidden mb-[3rem] md:block'>
            <p onClick={() =>toggleTab(3)} className={toggelState === 3 ? " cursor-pointer  text-[#9672FF] md:text-[23px] border-solid md:border-b-[1.5px] border-[#0095B4]": "text-dark md:text-[23px] cursor-pointer"}> Laundromat</p>

            </div>
            <div className="">
                
                <div className="grid justify-items-center bg-[#F2B8EC] mb-[2rem] py-[1rem] px-[1rem] rounded-bl-[1rem] rounded-br-[1rem] rounded-tr-[1rem] md:rounded-xl">
                <img src={icon3} className="w-[100%] md:w-[100%]  xl:w-[100%]" alt="" />

                </div>
            {/* <img src={connect1} alt="" /> */}
            </div>
            <div>
            <h4 className='text-[#FF46EC] md:text-dark font-[600] md:text-[20px]'>Payments</h4>
        <p className='text-[0.9rem] md:text-[1rem]'>Once verification is done by both parties, we proceed to paying or being paid</p> 

            </div>
        
        </div>

     

    
 </div>

   




 </div>
             </div>
         </section>
      </>
   );
}
 
export default HowItWorks;



