import img1 from '../assets/fl.png'
import img2 from '../assets/fl1.png'
import { useState } from 'react';

const FeaturedListing = () => {
    const[modal, setModal] = useState(false)

    const clicked = () =>{
        setModal(!modal);
        
    }




    return ( 
        <section id="testimonials" className="container mt-[4rem] mb-[10rem]">

{modal && (
 <div className="   bg-zinc-200 opacity-100 fixed inset-0 z-50   ">
  
 <div className=" h-screen p-[1rem] justify-center items-center ">
<p className="text-[25px] font-[600] max-w-[390px]">
                    25 ACRES OF LAND 
                    </p>
                    <p className="text-[25px] font-[600] max-w-[390px]">
                 IBEJU LEKKI
                    </p>
                   <p className='my-[1rem] text-[#545A58]'>
                   Suitable for all  you want <br /> Be it to Build <br /> Or to Farm.
                       </p> 

                       

                    <div className=' max-w-[390px] grid grid-cols-2 gap-y-[1.6rem] md:grid-cols-3'>
                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Budget</p>
                        <p className='text-[#545a58]'>Confidential</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Size</p>
                        <p className='text-[#545a58]'>1000mm</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Type</p>
                        <p className='text-[#545a58]'>Office</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Status</p>
                        <p className='text-[#545a58]'>Available</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Location</p>
                        <p className='text-[#545a58]'>Ibeju-Lekki Lagos</p>
                        </div>


                    </div>

                    {/* close modal */}
                    <button className=' bg-[#004D93] text-center my-[3rem] rounded py-3 px-[2.5%] text-white'  onClick={clicked}>Close Modal</button>

</div>
     </div>
     
)}
       <div className="bg-lemon ">
            <h1 className='text-[20px] md:text-[30px] font-[600] pt-[3rem]  text-dark text-center '>Featured Listing of the Week</h1>

                <div className="flex  flex-col  gap-[1.8rem] md:flex-row p-[3rem]">
                    <div className="md:w-1/2 mb-[-100px] md:mb-[-300px]">
                        <img src={img1} className="w-screen md:w-[70%]" alt="" />
                        <img src={img2}className="mt-[-2.4rem] overflow-hidden mb-0 w-[400px]" alt="" />
                    </div>
                    <div className='md:hidden mt-[1rem]'>
                        <div className='grid place-items-center'>
                        <p onClick={clicked}  className='text-[#004D93] cursor-pointer underline text-center '>See details</p>
                        {/* <div className='mt-[3rem]'> */}
                        <a href="https://wa.me/2348120674862" className='max-w-[100%]  text-center bg-[#004D93] px-[0.4rem] my-[3rem] rounded px-[1rem] py-2 text-white' >YES! I WANT A MEETING</a>

                        {/* </div> */}

                        </div>
                      
                    </div>
                    

                    
                <div className="md:w-1/2 hidden md:block">
                    <p className="text-[25px] font-[600] max-w-[390px]">
                    25 ACRES OF LAND 
                    </p>
                    <p className="text-[25px] font-[600] max-w-[390px]">
                 IBEJU LEKKI
                    </p>
                   <p className='my-[1rem] text-[#545A58]'>
                   Suitable for all  you want <br /> Be it to Build <br /> Or to Farm.
                       </p> 

                 

                    <div className=' max-w-[390px] grid grid-cols-2 gap-y-[1.6rem] md:grid-cols-3'>
                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Budget</p>
                        <p className='text-[#545a58]'>Confidential</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Size</p>
                        <p className='text-[#545a58]'>1000mm</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Type</p>
                        <p className='text-[#545a58]'>Office</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Status</p>
                        <p className='text-[#545a58]'>Available</p>
                        </div>

                        <div className='flex gap-y-[0.8rem] flex-col'>
                        <p className='text-dark font-[700]'>Location</p>
                        <p className='text-[#545a58]'>Ibeju-Lekki Lagos</p>
                        </div>


                    </div>
                    <div className='mt-[4rem]'>
                    <a href="https://wa.me/2348120674862" className=' bg-[#004D93] text-center my-[3rem] rounded py-3 px-[1.5rem] text-white' >YES! I WANT A MEETING</a>

                    </div>


                </div>

                </div>

            </div>
        
        </section>

     );
}
 
export default FeaturedListing;