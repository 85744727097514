import Navbar from './components/Navbar'
import Hero from './components/hero'
import Footer from './components/footer'
import CardSection from './components/CardSection'
import FeaturedListing from './components/FeaturedListing'
import FeaturedImagesSection from './components/FeaturedImagesSection'
import HowItWorks from './components/HowItWorks'

function App() {
  return (
    <>
    <Navbar />
    <Hero />
    <HowItWorks />
    <FeaturedImagesSection />
    <FeaturedListing />
    <CardSection />
    <Footer />

    
  
    </>
  );
}

export default App;
