import React from 'react';

const DisplayFeatured = ({ item }) => {
  return (
    <section>

    <div key={item.id} className="">
      <div className='mb-8'>
        <div className='relative'>
        <img className='' src={item.image} alt=''  />
        <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-[0.2rem] py-[0.2rem] md:px-3 md:py-2 rounded-lg z-10 bg-[#222222] opacity-60 border-solid border-[1px] border-white text-sm font-medium text-white select-none">
			Featured
		  </span>
        </div>
        
      </div>
      {/* <p className='capitalize text-accent text-sm mb-3'>{item.category}</p> */}
     
     
    </div>

</section>

  );
};

 export default  DisplayFeatured;