import FeaturedCarousel from "./FeaturedCarousel";

import mob1 from '../assets/realestatemob.png'
import mob2 from '../assets/Image 04.png'
import mob3 from '../assets/Image 05.png'
import DisplayFeatured from "./DisplayFeatured";
import { LaundromatData, LogisticsData } from "../data";

const FeaturedImagesSection = () => {
    return ( 
        <section id="services" className=" md:bg-[#F3F3F3]  ">
            <div className=" hidden md:block container md:py-[5rem] p-[2rem]">
                <div className="flex flex-col justify-between md:flex-row">
                    <div className="">
                        <h1 className="md:text-[35px]  font-[600]">Best Real Estate Deals</h1>
                        <p className="text-[0.9rem] max-w-[450px] my-[1.2rem]"> These listings and many more are our bought and available to sell properties; contact us for easy transactions.</p>
                    </div>

                    <div>
                        <a  href="https://wa.me/2348120674862" className="bg-red shadow-2xl py-[0.4rem] px-[0.4rem] text-white rounded xl:py-[1.2rem] xl:px-[3rem]">Contact Us</a>
                    </div>
                </div>

                <FeaturedCarousel />
            </div>

            <div className="container hidden md:block md:py-[5rem] p-[2rem]">
                <div className="flex flex-col justify-between md:flex-row">
                    <div className="">
                        <h1 className="md:text-[35px]  font-[600]">Our Logistics Deals</h1>
                        <p className="text-[0.9rem] max-w-[450px] my-[1.2rem]">We do all form of logistics, pick up and delivery all over the country and outside the country.</p>
                    </div>

                    <div>
                        <a href="https://wa.me/2348120674862" className="bg-[#4DDFFD] py-[0.4rem] px-[0.4rem] shadow-2xl text-white rounded xl:py-[1.2rem] xl:px-[3rem]">Contact Us</a>
                    </div>
                </div>

                <div className="grid gap-x-[1rem] mt-[2rem] gap-y-[1rem] grid-cols-2 md:grid-cols-3">
                {LaundromatData.map((item)=>{
                 
              
              return <DisplayFeatured item={item} key={item.id} />
                })}
            </div>
            </div>

            <div className="container hidden md:block md:py-[5rem] p-[2rem]">
                <div className="flex flex-col justify-between md:flex-row">
                    <div className="">
                        <h1 className="md:text-[35px]  font-[600]">Our Laundromat Deals</h1>
                        <p className="text-[0.9rem] max-w-[450px] my-[1.2rem]">We are known for delivering the best, we pick it up and deliever your clean and ironed ready to wear clothes.</p>
                    </div>

               <div>
               <a href="https://wa.me/2348120674862" className="bg-[#004D93] py-[0.4rem] px-[0.4rem] shadow-2xl text-white rounded xl:py-[1.2rem] xl:px-[3rem]">Contact Us</a>

               </div>
                 
                </div>

            
                <div className="grid gap-x-[1rem] mt-[2rem] gap-y-[1rem] grid-cols-2 md:grid-cols-3">
                {LogisticsData.map((item)=>{
                 
              
              return <DisplayFeatured item={item} key={item.id} />
                })}
            </div>

                {/* <FeaturedCarousel /> */}
            </div>
            



            {/* p-10 min-h-screen flex items-center justify-center bg-teal-400 */}
 <div data-aos="fade-down" className="container mb-[4rem] md:hidden">
<div className="w-full max-w-lg h-64 rounded-lg shadow-2xl overflow-hidden relative">
  <img className="absolute inset-0 h-full w-full object-cover" src={mob1} alt="" />
  <div className="absolute inset-0 bg-gray-900 bg-opacity-5"></div>
  <div className="absolute bottom-1 left-2 right-1">
    <h1 className="text-[16px] text-white tracking-wider">We have sold and purchased so many in the few weeks, months and years. </h1>
  </div>
  <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-[0.5rem] py-[0.5rem]  rounded-lg z-10 bg-[#0057A5]  border-solid border-[1px] border-white text-sm font-medium text-white select-none">
			Real Estate Deals
		  </span>
</div>
</div> 

<div data-aos="fade-down" className="container mb-[4rem] md:hidden">
<div className="w-full max-w-lg h-64 rounded-lg shadow-2xl overflow-hidden relative">
  <img className="absolute inset-0 h-full w-full object-cover" src={mob2} alt="" />
  <div className="absolute inset-0 bg-gray-900 bg-opacity-5"></div>
  <div className="absolute bottom-1 left-2 right-1">
    <h1 className="text-[16px] text-white tracking-wider">We have sold and purchased so many in the few weeks, months and years. </h1>
  </div>
  <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-[0.5rem] py-[0.5rem]  rounded-lg z-10 bg-[#0057A5]  border-solid border-[1px] border-white text-sm font-medium text-white select-none">
			Logistics
		  </span>
</div>
</div> 

<div data-aos="fade-down" className="container md:hidden">
<div className="w-full max-w-lg h-64 rounded-lg shadow-2xl overflow-hidden relative">
  <img className="absolute inset-0 h-full w-full object-cover" src={mob3} alt="" />
  <div className="absolute inset-0 bg-gray-900 bg-opacity-5"></div>
  <div className="absolute bottom-1 left-2 right-1">
    <h1 className="text-[16px] text-white tracking-wider">We have sold and purchased so many in the few weeks, months and years. </h1>
  </div>
  <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-[0.5rem] py-[0.5rem]  rounded-lg z-10 bg-[#0057A5]  border-solid border-[1px] border-white text-sm font-medium text-white select-none">
			Laundromat
		  </span>
</div>
</div> 

<div className="container grid place-items-center my-[4rem] md:hidden">
   <a href="https://wa.me/2348120674862" className="bg-[#F85A47] py-[1.2rem] px-[5rem] shadow-2xl text-white rounded xl:py-[1.2rem] xl:px-[3rem]">Contact Us</a>
  </div>

        </section>

     );
}
 
export default FeaturedImagesSection;