import React , {useState} from 'react'
import {MenuAlt3Icon} from '@heroicons/react/outline'
import logo from '../assets/letterheadlogo 1.png'
import mobileLogo from '../assets/mobilelogo.png'
import {Link} from 'react-scroll'
const Navbar = ({color}) => {
    const [nav, setNav] = useState(false);
    // const [active, setActive] = useState(false);
    // const activeColor = () => setActive

    const handleClick = () => setNav(!nav);

    return (  
       <div className="container pr-8 mt-[2rem]">
           <div className="flex flex-row justify-between items-center">
               <div>
                   <img src={logo} className="hidden md:flex" alt="" />
                   <img src={mobileLogo} className="md:hidden" alt="" />
               </div>

               <div className="hidden md:flex z-[200] space-x-6" >
                   <Link   to="hero" spy={true} smooth={true} offset={50} duration={500} className = {color ? color:'cursor-pointer text-white '}>Home</Link>
                   <Link  to="about" spy={true} smooth={true} offset={50} duration={500} className={color ? color:'text-white cursor-pointer'}>About</Link>
                   <Link  to="services" spy={true} smooth={true} offset={50} duration={500} className={color ? color:'text-white cursor-pointer'}>Services</Link>
                   <Link  to="testimonials" spy={true} smooth={true} offset={50} duration={500} className={color ? color:'text-white cursor-pointer'}>Portfolio</Link>
                   <Link  to="contact" spy={true} smooth={true} offset={50} duration={500} className={color ? color:'text-white cursor-pointer'}>Contact</Link>
               </div>
               
               <MenuAlt3Icon className='lg:hidden w-5 cursor-pointer' onClick={handleClick} />

               
               <div className={!nav ? 'xl:hidden hidden':'xl:hidden z-[999]  absolute flex flex-col items-center self-end  py-8  space-y-6 font-bold bg-[#fbfbfb] top-[3rem] left-[2.5rem] right-[2.5rem] drop-shadow-md'} >
                   {/* <a href="/" className='text-black'>Home</a>
                   <a href="/" className='text-black'>About</a>
                   <a href="/" className='text-black'>Services</a>
                   <a href="/" className='text-black'>Portfolio</a>
                   <a href="/" className='text-black'>Contact</a> */}

                   <Link   to="hero" spy={true} smooth={true} offset={50} duration={500} className = "text-black cursor-pointer " >Home</Link>
                   <Link  to="about" spy={true} smooth={true} offset={50} duration={500}  className = "text-black cursor-pointer">About</Link>
                   <Link  to="services" spy={true} smooth={true} offset={50} duration={500} className = "text-black cursor-pointer" >Services</Link>
                   <Link  to="testimonials" spy={true} smooth={true} offset={50} duration={500}  className = "text-black cursor-pointer">Portfolio</Link>
                   <Link  to="contact" spy={true} smooth={true} offset={50} duration={500}  className = "text-black cursor-pointer">Contact</Link>
               </div>
           </div>

       </div>
    );
}
 
export default Navbar;