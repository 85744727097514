import imgTest1 from '../assets/test1.png'
import imgTest2 from '../assets/test2.png'
import imgTest3 from '../assets/test3.png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { StarIcon } from '@heroicons/react/outline'
const CardSection = () => {

    return ( 
    <section  className="bg-lighterBlue py-[42px] pb-[3rem]">
            
  

            <div className="container">

            <h3 className="text-[25px] md:text-[35px] text-white font-[500]">
            Loved by businesses, and <br /> individuals across the globe.
            </h3>
            <div className='xl:hidden'>

            <Swiper slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}     autoplay={true}
        modules={[Pagination, Autoplay]}
        className="" >
            <div className=" grid grid-cols-1 w-[500px]  grid-cols">
   
<SwiperSlide>
                <div className=" mt-4 pl-[2rem] hover:opacity-75   pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star stroke-amber-400"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[2rem]">
                    Mere thinking of washing got me really stressed not until I was referred here. I tested and yeah you delivered well.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest1} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>AnuOluwapo</h4>
                            <small className='text-[#666666] '>Ajah</small>
                        </div>
                    </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>

                <div className=" mt-4 pl-[2rem]  hover:opacity-75  pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[2rem]">
                    I’ll soon get to building, thanks for the land’s transaction. Relatively cheap and also you have one of the best customer service.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest2} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>Joshua J</h4>
                            <small className='text-[#666666] '>Lekki</small>
                        </div>
                    </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>
              


                <div className="mt-4 pl-[2rem] hover:opacity-75   pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[2rem]">
                    I’ve patronized all of your services and I must say, you deliver the best no jokes. Thanks to everyone at Merlase.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest3} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>Laide Olupoju</h4>
                            <small className='text-[#666666] '>Texas</small>
                        </div>
                    </div>
                </div>
                </SwiperSlide>


                
            </div>

            </Swiper>
            </div>

            <div className="hidden xl:grid grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-x-[1rem] justify-items-center grid-cols">
                <div className="mt-4 pl-[2rem] hover:opacity-75   pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star stroke-amber-400"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[1rem] md:my-[2rem]">
                    Mere thinking of washing got me really stressed not until I was referred here. I tested and yeah you delivered well.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest1} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>AnuOluwapo</h4>
                            <small className='text-[#666666] '>Ajah</small>
                        </div>
                    </div>
                </div>


                <div className=" mt-4 pl-[2rem]  hover:opacity-75  pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[1rem] md:my-[2rem]">
                    I’ll soon get to building, thanks for the land’s transaction. Relatively cheap and also you have one of the best customer service.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest2} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>Joshua J</h4>
                            <small className='text-[#666666] '>Lekki</small>
                        </div>
                    </div>
                </div>


                <div className="mt-4 pl-[2rem] hover:opacity-75   pr-[3.5rem] py-[3rem] bg-white rounded-[10px]">
                        <div className='flex  flex-row'>
                        <StarIcon className="w-4 star"  />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        <StarIcon className="w-4 star" />
                        </div>
                        
                    <p className="my-[1rem] md:my-[2rem]">
                    I’ve patronized all of your services and I must say, you deliver the best no jokes. Thanks to everyone at Merlase.
                    </p>

                    <div className="flex  flex-row">
                        <img src={imgTest3} alt="" />
                        <div className="ml-[1rem]">
                            <h4 className='text-dark'>Laide Olupoju</h4>
                            <small className='text-[#666666] '>Texas</small>
                        </div>
                    </div>
                </div>


                
            </div>


            <div className="flex mt-[3.4rem] flex-col gap-y-[3rem]  md:flex-row justify-between ">
                <div className="md:grid  hidden  md:grid-cols-4 gap-4 text-center">
                    <div className=' flex flex-col'>
                    <h1 className="text-white text-[2rem] font-[800] ">11K+</h1>
                    <p className="text-white  ">Clients</p>

                </div>
                <div className='flex flex-col'>
                    <h1 className="text-white text-[2rem] font-[800]">16K+</h1>
                    <p className="text-white">Properties Trade</p>

                </div>

                <div className='flex flex-col'>
                    <h1 className="text-white text-[2rem] font-[800]">11K+</h1>
                    <p className="text-white">Logistics</p>

                </div>

                <div className='flex flex-col'>
                    <h1 className="text-white text-[2rem] font-[800]">2K+</h1>
                    <p className="text-white">in 2 years</p>

                </div>
                </div>
                
               
                <div className="">
                    <div className='text-white '>
                   <span> Give us a Call 08120674862 and we <br />
                     can set you up or</span>
                     <span>
                     <a href='https://wa.me/2348120674862' className="ml-2 underline">
                     text us on whatsapp</a>
                         </span>  
                   
        
                    </div>
                </div>


            </div>
            </div>
            


        </section>
     );
}
 
export default CardSection;