import img1 from './assets/residential1.png'
import img2 from './assets/residential2.png'
import img3 from './assets/land1.png'
import img4 from './assets/land2.png'
import img5 from './assets/logistics1.png'
import img6 from './assets/logistics2.png'
import img7 from './assets/logistics3.png'
import img8 from './assets/laundromat1.png'
import img9 from './assets/laundromat2.png'
import img10 from './assets/laundromat3.png'
import heroImg1 from './assets/Header Image (4).png'
import heroImg2 from './assets/Header Image (1).png'
import heroImg3 from './assets/Header Image (2).png'
import mobile1 from './assets/mobreal.png'
import mobile2 from './assets/moblogistics.png'
import mobile3 from './assets/moblaund.png'


// Featured Image Nav
export const FeaturedNav = [
    {
      name: 'All',
      id:1
    },
    {
      name: 'Buildings',
      id:2
    },
    {
      name: 'Land',
      id:3
    },
  
  ];
export const LaundromatNav = [
  {
    name: 'All',
    id:1
  },
    {
      name: 'Buildings',
      id:2
    },
    {
      name: 'Land',
      id:3
    },
  
  ];

export const LogisticsNav = [
  {
    name: 'All',
    id:1
  },
    {
      name: 'International logistics',
      id:2
    },
    {
      name: 'Local Deals',
      id:3
    },
  
  ];


export const heroSectionData = [
  {
    header:"Real Estate; We Buy, We Sell Properties globally.",
    desc:"We buy and we sell properties, contact us today and we get straight to business, we are well known for doing it easy and fast.",
    img:heroImg2,
    mobileImg:mobile1,
    mobileHeader1:"Real Estate;",
    mobileHeader2:"We Buy, We Sell Properties globally.",
  },
  {
    header:"Logistics; Smart Living Style for Smart People",
    desc:"Don’t be at distress and leave the stress for us, we will handle all sorts of movement be it goods or human. It’s a smart living style.",
    img:heroImg1,
    mobileImg:mobile2,
    mobileHeader1:"Logistics;",
    mobileHeader2:"Smart Living Style for Smart People",

  },
  {
    header:"Laundromat; Smart Living Style for Smart People",
    desc:"We’re the leader in making it easy, Pickup & Delivery of perfectly cleaned and ironed wears, we make it ready to wear.",
    img:heroImg3,
    mobileImg:mobile3,
    mobileHeader1:"Laundromat;",
    mobileHeader2:"Smart Living Style for Smart People",

  }
]

export const CarouselData = [
    {
        id:1,
        image:img1,
        category: 'Buildings',
    },
    {
        id:2,
        image:img2,
        category: 'Buildings',
    },

    {
      id:3,
      image:img3,
      category: 'Land',


  },
    {
      id:4,
      image:img4,
      category: 'Land',


  },

]

export const LogisticsData = [
  {
      id:1,
      image:img5,
      category: 'International Logistics',
  },
  {
      id:2,
      image:img6,
      category: 'International Logistics',
  },
  {
      id:3,
      image:img7,
      category: 'International Logistics',
  },







]

export const LaundromatData = [
  {
      id:1,
      image:img8,
      category: 'International Deals',
  },

  {
    id:2,
    image:img9,
    category: 'Local Deals',


},
  {
    id:3,
    image:img10,
    category: 'Land',


},


]
export const HowItWorksNav = [
  {
    name: 'Real Estate',
    id:1
  },
  {
    name: 'Logistics',
    id:2
  },
  {
    name: 'Laundromat',
    id:3

  },
]
export const HowItWorksData = [
  {
    id:1,
    name:'Real Estate',
    header:'Contact Us',
    text:'Clik on any of our contact us buton, you will instantly be with any of our representatives.',
    category:'Real Estate'

    
  },
  {
    id:2,
    name:'Real Estate',
    header:'Contact Us',
    text:'Clik on any of our contact us buton, you will instantly be with any of our representatives.',
    category:'Real Estate'

    
  },
  {
    id:3,
    name:'Real Estate',
    header:'Contact Us',
    text:'Clik on any of our contact us buton, you will instantly be with any of our representatives.',
    category:'Real Estate'

    
  },
  {
    id:4,
    name:'Logistics',
    header:'Survey Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
    category:'Logistics'
    

  },
  {
    id:5,
    name:'Logistics',
    header:'Survey Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
    category:'Logistics'
    

  },
  {
    id:6,
    name:'Logistics',
    header:'Survey Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
    category:'Logistics'
    

  },
  {
    id:7,
    name:'Laundromat',
    header:'Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
   category:'Laundromat'

  },
  {
    id:8,
    name:'Laundromat',
    header:'Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
   category:'Laundromat'

  },
  {
    id:9,
    name:'Laundromat',
    header:'Properties',
    text:'Click on any of our contact us button, you will instantly be with any of our representatives.',
   category:'Laundromat'

  }
]



