import Navbar from './Navbar'
const Footer = () => {
    return ( 
        <section id="contact" className='p-[1rem] md:p-[5rem] pt-[1.5rem] mt-[3rem]  bg-lightGray'>
            <div className='grid mb-[10rem] text-center items-center justify-items-center'>
                <h1 className='md:text-[40px] text-[24px] font-[500] text-#31353B'>Contact Us</h1>
                <p className="md:text-[23px] text-[16px] font-[500] my-[1rem]">We offer the best customer services ever with immediate response</p>

                <div className="mt-4 ">
                    
                    <div>
                    <a href="mailto:Entmerlase@gmail.com" className="bg-[#00315E] px-[5rem] md:px-[9rem] rounded-lg py-[1.1rem] text-white">Email Us</a>
                    </div>
                    <br />
                    <div className='mt-[2.5rem]'>
                    <a href="https://wa.me/2348120674862" className="bg-red  px-[4rem] md:px-[8rem] rounded-lg mt-[rem] py-[1.1rem] text-white">WhatsApp Us</a>

                    </div>
      
                    </div>

                
              

            </div>
            <hr className='bg-[#FBFCFC]' />

<Navbar color="text-black" />
        </section>
      
     );
}
 
export default Footer;