import { CarouselData, FeaturedNav } from '../data';
import {useState, useEffect} from 'react'
import DisplayFeatured from './DisplayFeatured';
import { motion } from "framer-motion"
const FeaturedCarousel = () => {
    const [item, setItem] = useState({name:"all"});
    const [propImages, setPropImages] = useState([]);
    const [active, setActive] = useState(0);
    const FeaturedProperties = CarouselData[0];
    useEffect(()=>{
       
        // console.log(propImages)
        // console.log(FeaturedProperties)
        //  setPropImages(FeaturedProperties)
        if(item.name === "all"){
            setPropImages(CarouselData);
            // console.log(propImages)
        }
        else{
            const newPropsImages = CarouselData.filter((data) =>{
                return data.category.toLowerCase() === item.name;
            });
            setPropImages(newPropsImages);
       
        }
       
    }, [item])

    const handleClick = (e, index) => {
        setItem({ name: e.target.textContent.toLowerCase() });
    // console.log(e.target.textContent)
        
        setActive(index);
      };

    return ( 
        <section className=" mt-[3rem]">
            <nav>
                {/*  grid grid-cols-2 md:grid-cols-4 gap-y-[2rem]*/}
                <ul className=' flex flex-col md:flex-row md:max-w-[400px] justify-between'>
                    {FeaturedNav.map((item, index)=>{
                     
                        return <li onClick={(e) => {handleClick(e, index);}} className={`${
                            active === index ? 'active text-red border-solid md:border-b-[1.5px] border-red' : ''
                          } cursor-pointer capitalize`}
                         key={index}>{item.name}</li>
                    })}
                </ul>
            </nav>
            

            <div className="grid gap-x-[1rem] mt-[2rem] gap-y-[1rem] grid-cols-2 md:grid-cols-3">
                {propImages.map((item, index)=>{
                 
              
              return <DisplayFeatured item={item} key={item.id} />
                })}
            </div>


        </section>
     );
}
 
export default FeaturedCarousel;