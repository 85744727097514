import React, {useState, useEffect} from "react";
import { heroSectionData } from "../data";
import { PhoneIcon, ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import 'aos/dist/aos.css';
import Aos from 'aos'
import CountUp from 'react-countup';


const Hero = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = heroSectionData.length;
    const autoScroll = true;
    let slideInterval;
    let intervalTime = 6000;


    const nextSlide = () =>{
        setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    }
    const prevSide = () =>{
        setCurrentSlide(currentSlide === 0 ? slideLength -1 : currentSlide - 1)
    }

    function auto(){
        slideInterval = setInterval(nextSlide, intervalTime)
    }
    useEffect(() =>{
        Aos.init({duration:1000});
    }, [])

    useEffect(()=>{
        setCurrentSlide(0);
    }, []);


    useEffect(()=>{
        if (autoScroll){
            auto();

        }
        return () => clearInterval(slideInterval);

        
    }, [currentSlide]);


    return ( 
        <section className="mb-[6rem]">
            
{heroSectionData.map((slide, index) =>{
    return <div className={index === currentSlide ? 'slide current':'slide'} key={index}>
        {index === currentSlide && (
            <div>
                <div className="container relative" >
                <div className="hidden xl:block xl:bg-[#F1C5ED] xl:absolute xl:right-[0] -mt-[8rem] xl:top-0 xl:h-[650px] xl:w-[400px]">

</div>
<div className="hidden xl:flex xl:absolute xl:right-[2rem] top-[470px]  flex-row gap-x-3">
    <ChevronLeftIcon className="w-6 cursor-pointer prev arrow z-[999]" onClick={prevSide} />
    <ChevronRightIcon  className="w-6 cursor-pointer next arrow z-[999]" onClick={nextSlide} />
    </div>

<div className="grid grid-cols-1 justify-items-center gap-y-[3rem] xl:grid-cols-2 text-center align-items   xl:text-left transition ease-in">

    <div  className="mt-[2rem]   flex flex-col space-y-6">
        <p className="font-[800] ">
        <span className="text-navyBlue md:text-[17px] text-[11px]">MERLASE </span> 
        <span className="text-[#fbb040] md:text-[17px]  text-[11px]">REAL ESTATE, LOGISTICS & LAUNDROMAT</span> 
        </p>
        <div className="hidden xl:block">
        <h3 data-aos="fade-down" className="text-dark text-[20px] xl:px-0 px-[2rem] font-[800] xl:max-w-[620px] drop-shadow-md md:text-[34px] xl:text-[45px]  xl:drop-shadow-xl">
 {slide.header}
        </h3>
        <p data-aos="fade-down" className="text-#31353B-100 xl:max-w-[430px] xl:px-0 px-[2rem] text-[17px]">
            {slide.desc}
        </p>
        </div>
        <div className="xl:hidden">
           <h3 data-aos="fade-down" className="text-dark text-[16px] xl:px-0 px-[2rem] font-[800] xl:max-w-[620px] drop-shadow-md md:text-[34px] xl:text-[45px]  xl:drop-shadow-xl" >{slide.mobileHeader1}</h3> 
           <h3 data-aos="fade-down" className="text-dark text-[16px] xl:px-0 px-[2rem] font-[800] xl:max-w-[620px] drop-shadow-md md:text-[34px] xl:text-[45px]  xl:drop-shadow-xl" >{slide.mobileHeader2}</h3>
           <p data-aos="fade-down" className="text-#31353B-100 xl:max-w-[430px] mt-[2rem] xl:px-0 px-[2rem] text-[14px]">
            {slide.desc}
        </p>
        </div>
   
        <div className="flex  items-center h-[3rem] rounded-[2rem] mx-auto xl:ml-0 xl:max-w-[300px] drop-shadow-sm justify-center flex-row bg-navyBlue space-x-3 text-white p-4 xl:rounded">
        <PhoneIcon className="w-5"/> <a className="" href="https://wa.me/2348120674862">  Contact us</a>
        </div>
        {/*  */}
        <div className="hidden xl:grid  grid-cols-2 md:grid-cols-4 ">
            <div className=' flex flex-col'>
            <CountUp end={11} suffix="K+" className="text-[#004D93] text-[1rem] md:text-[2rem] font-[800] "></CountUp>
            <p className="text-black  text-[1rem]">Clients</p>

        </div>
        <div className='flex flex-col'>
            <CountUp end={16} suffix="K+" className="text-[#004D93] text-[1rem] md:text-[2rem] font-[800]"></CountUp>
            <p className="text-black text-[1rem]">Properties Trade</p>

        </div>

        <div className='flex flex-col'>
            <CountUp end={11} suffix="K+"  className="text-[#004D93] text-[1rem] md:text-[2rem] font-[800]"></CountUp>
            <p className="text-black text-[1rem]">Logistics</p>

        </div>

        <div className='flex flex-col'>
            <CountUp end={2} suffix="K+"  className="text-[#004D93] text-[1rem] md:text-[2rem] font-[800]"></CountUp>
            <p className="text-black text-[1rem]">in  2 years</p>

        </div>
        </div>
    </div>
    {/*   */}
    
    <div data-aos="fade-up" className="xl:block mb-[-50px] xl:-mt-[12rem] xl:absolute xl:right-[0rem] ">
        <img src={slide.img} className="hidden xl:block xl:w-full  xl:h-[800px]" alt="" />
        
        <img src={slide.mobileImg} className="xl:hidden w-screen h-[90%] drop-shadow-xl" alt="" />
        {/* <div className="xl:hidden  -mt-[15rem] md:block">
            <div className="bg-[#E5EDF4] w-screen h-[200px]">
            
            </div>
        </div> */}
        
        
       </div>
          <div className="xl:hidden text-center">
            <h1 className="text-center md:text-[24px] text-[16px] font-[800]">Trusted by all</h1>
        </div>
      

</div>
</div>
            </div>
        )}
 
    </div>
})}
       
        </section>

     );
}
 
export default Hero;